@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);


::-webkit-scrollbar{
    height: 10px;
    background: rgba(66, 66, 66, 0.3);
}
::-webkit-scrollbar-thumb:horizontal{
    background: rgba(66, 66, 66, 0.6);
    border-radius: 10px;
}


.img-spin {
    animation: rotation 2s infinite linear;
}

tbody > tr{
    border-bottom: 1px solid lightslategrey;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}
html,body{
  height: 100% !important;
  width: 100% !important;
  font-family: 'Inter', sans-serif;
  padding-right: 0px !important;
}
