

::-webkit-scrollbar{
    height: 10px;
    background: rgba(66, 66, 66, 0.3);
}
::-webkit-scrollbar-thumb:horizontal{
    background: rgba(66, 66, 66, 0.6);
    border-radius: 10px;
}


.img-spin {
    animation: rotation 2s infinite linear;
}

tbody > tr{
    border-bottom: 1px solid lightslategrey;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}